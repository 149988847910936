
export default class Delegation{
  /// Delegation token should not be necessary once everyone moves to V2
  /// That day is not here yet

  constructor(auth){
    this.auth = auth;
  }

  // get a delegation token for the given target clientid.
  getDelegationToken = (targetClientId) => {
    console.warn("Simple Auth Wrapper:  Delegation authorization is deprecated.  Services requiring V1 auth should be upgraded to V2.");
    // check a cache we have in localStorage first as these things are expensive to build
    let delegationTokens = JSON.parse(localStorage.getItem('delegationTokens') || '{}') || {};
    let delegationTokenWrapper = delegationTokens[targetClientId];

    let tokenValid = false;
    try {
      var expiresAt = delegationTokenWrapper.expiresAt;
      tokenValid = new Date().getTime() < expiresAt;
    } catch (e) {
      tokenValid = false;
    }

    if (delegationTokenWrapper && tokenValid) {
      return Promise.resolve(delegationTokenWrapper.token);
    } else {
      let headers = new Headers({
        'Content-Type': 'application/json'
      });
      let body = {
        client_id: this.auth.clientID, //our client id
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        id_token: localStorage.getItem('token'), // user"s id token
        target: targetClientId, //target app client id
        scope: this.auth.scope,
        api_type: 'api'
      };

      return fetch(`https://${this.auth.domain}/delegation`, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error(`Error fetching delegation token for ${targetClientId}`);
          }
        })
        .then(data => {
          let token = data ? data.id_token : '';
          let tokenWrapper = {
            token,
            expiresAt: JSON.stringify(data.expires_in * 1000 + new Date().getTime())
          };
          delegationTokens[targetClientId] = tokenWrapper;
          localStorage.setItem('delegationTokens', JSON.stringify(delegationTokens));
          return token;
        });
    }
  };

  // build some fetch headers, getting the delegation token if needed
  buildDelegationHeader = (targetClientId) => {
    return this.getDelegationToken(targetClientId).then(token => {
      let delegationToken = 'Bearer ' + token;
      return new Headers({
        Accept: 'application/json',
        Authorization: delegationToken
      });
    });
  };
}
