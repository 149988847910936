import Auth0 from 'auth0-js';
import { promisify } from './promisify';

export default class UserMetadata{
  // requires either an embeddedAuth(default or centralizedAuth)
  constructor(auth) {
      this.auth = auth;
  }

  generateAuthManage = () => {
    if (!this.auth.isLoggedIn()) {
      throw new Error('User must be logged in to perform management tasks');
    }

    let idToken = this.auth.getToken();

    return new Auth0.Management({
      domain: this.auth.domain,
      token: idToken
    });
  };

  /// Default scoped to the clientID
  getUserMetadata = (applicationKey) => {
    var auth0Manage = this.generateAuthManage();
    let key = applicationKey || this.auth.clientID;
    let getUser = promisify(auth0Manage.getUser.bind(auth0Manage));

    let profile = this.auth.getProfile();

    return getUser(profile.sub).then(userData => {
      if(userData && userData.user_metadata && userData.user_metadata[key]){
        return userData.user_metadata[key];
      }
      return {};
    });
  };

  /// Default scoped to the clientID
  patchUserMetadata = (metadata, applicationKey) => {
    var auth0Manage = this.generateAuthManage();
    let key = applicationKey || this.auth.clientID;
    let patchUser = promisify(auth0Manage.patchUserMetadata.bind(auth0Manage));

    let profile = this.auth.getProfile();
    let userMetadata = {};
    userMetadata[key] = metadata;

    return patchUser(profile.sub, userMetadata).then(userData => {
      return userData.user_metadata[key];
    });
  };
}
